<template>
  <div>
    <blockquote class="my-blockquote">邮件服务器配置</blockquote>
     <el-button style="margin-left:10px;" size="medium" type="primary" icon="el-icon-plus" @click="editconfig()">新增配置</el-button>
    <el-button :loading="loading" style="margin-left:10px;" size="medium" icon="el-icon-refresh-right" type="primary" @click="init()">刷新</el-button>
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID" width="60px"/>
      <el-table-column label="邮箱类型" >
        <template slot-scope="scope">
          <span>{{scope.row.type ==0?'默认配置':scope.row.type == 1?'验证码邮箱':scope.row.type ==2?'通知邮箱':''}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="fromUser" label="发件人邮箱" />
      <el-table-column prop="user" label="发件用户名" />
      <el-table-column prop="pass" label="邮箱密码" />
      <el-table-column prop="host" label="SMTP地址" />
      <el-table-column prop="port" label="SMTP端口" width="100px" />
      <el-table-column label="是否开启ssl" width="100px" >
        <template slot-scope="scope">
          <span>{{scope.row.ssl == 'true'?'是':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"  align="center" width="250px">
        <template slot-scope="scope">
           <el-button
            type="success"
            size="mini"
            class="my-button"
            @click="editconfig(scope.row)"
          >编辑</el-button>
          <el-button
            type="warning"
            size="mini"
            class="my-button margin-right5"
            @click="openTest(scope.row)"
          >配置测试</el-button>
          <el-popover
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <blockquote class="my-blockquote"> 邮件服务器配置说明</blockquote>
    <pre class="my-code">
      # 邮箱类型说明：验证码邮箱用于发送验证码邮件，通知邮箱用于发送系统通知邮件(如跟单提示)，默认配置在前述两种邮箱均未配置的情况下用于发送验证码和通知邮件
      # 邮件协议默认为smtp
      # 邮件服务器的SMTP地址
      # 邮件服务器的SMTP端口，默认465或者25
      # 发件人（必须正确，否则发送失败）
      # 用户名，默认为发件人邮箱前缀
      # 密码（注意，某些邮箱需要为SMTP服务单独设置密码或者客户端密码，如QQ和163等等）
      # 是否开启ssl，开启使用ssl端口，未开启端口为25</pre>
      <Config ref='editconfig' :siteId='siteId' @refresh-list='init'/>
      <el-dialog
        :append-to-body="true"
        :visible.sync="testDialog"
        width="500px"
        title="发送测试邮件"
      >
        <el-input v-model="toEmail" placeholder="请输入收件邮箱" style="width: 70%;"/>
        <el-button :loading="loading" style="margin-left:2%;" size="medium" type="success" @click="sendEmail">发送邮件</el-button>
      </el-dialog>
  </div>

</template>

<script>
import Config from '@/components/tools/email/config'
import Send from '@/components/tools/email/send'
import Description from '@/components/tools/email/description'
import { get, configEmail,send,del } from '@/api/tools/email'
import { validatEmail } from '@/utils/validate'
import translate from 'i18n-jsautotranslate'
export default {
  components: { Config, Send, Description },
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  created() {
    this.init()
    this.translate = translate;
  },
  data() {
    return {
      form: { siteId:'',subject: '邮箱配置测试邮件', tos: [], content: '这是一封测试邮件，测试邮件配置是否成功，如收到邮件，恭喜你邮件配置正确！' ,type:''},
      toEmail:'',
      loading: false,
      testDialog:false,
      delLoading:false,
      testtype:'',
      data:[],
      translate:null
    };
  },
  methods: {
    init() {
      this.loading = true
      const params = {siteId:this.siteId}
      get(params).then(res => {
        this.loading=false
        if(res) {
          this.data = res
        }else{
          this.data = []
        }
        setTimeout(() => {
          var currentlang = this.translate.language.getCurrent();
            console.log("当前使用的是"+currentlang)
            // 获取所有具有指定class的元素
            var buttons = document.querySelectorAll('.cell .el-button');
            // 遍历这些元素
            buttons.forEach(function(button) {
                // 在该元素下查找所有的span元素
                var spans = button.getElementsByTagName('span');
                // 遍历并处理找到的span元素
                for (var i = 0; i < spans.length; i++) {
                  const translations = {
                    english: {
                        "编辑": "edit",
                        "删除": "delete",
                        "配置测试": "Configuration test",
                        
                    },
                    vietnamese: {
                      "编辑": "Sửa",
                      "删除": "Xóa",
                      "配置测试": "Cấu hình thử nghiệm",
                      
                    },
                    chinese_traditional:{
                      "编辑": "編輯",
                      "删除": "刪除",
                      "配置测试": "配置測試",
                      
                  }
                    // 可以在这里添加其他语言的翻译映射
                };
                function translateText(text, lang) {
                    if (translations[lang] && translations[lang][text]) {
                        return translations[lang][text];
                    }
                    return text; // 如果找不到翻译，返回原文本
                }
                
                if (currentlang != "chinese_simplified") {
                  for (let i = 0; i < spans.length; i++) {
                    spans[i].innerText = translateText(spans[i].innerText.trim(), currentlang);
                  }
                }
                }
            });
        },1000)
      })
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    editconfig(row) {
      const _this = this.$refs.editconfig
      if(row) {
        _this.form = row
      }
      _this.dialog = true
    },
    openTest(row){
      this.testtype = row.type
      this.testDialog=true
    },
    sendEmail() {
      const _this = this
      let sub = false;
      if (validatEmail(_this.toEmail)) {
          _this.form.tos.push(_this.toEmail)
        } else {
          _this.$message({
            message: '收件邮箱格式错误',
            type: 'warning'
          })
          sub = true
        }
      if (sub) { return false }
      this.loading = true
      this.form.siteId = this.siteId
      this.form.type=this.testtype
      send(this.form).then(res => {
        this.$notify({
          title: '发送成功',
          type: 'success',
          duration: 2500
        })
        this.form = { siteId:'',subject: '邮箱配置测试邮件', tos: [], content: '这是一封测试邮件，测试邮件配置是否成功，如收到邮件，恭喜你邮件配置正确！' },
        this.loading = false
      }).catch(err => {
        this.testDialog=false
        this.loading = false
        this.$notify({
          title: '发送失败，请检查邮箱配置',
          type: 'error',
          duration: 2500
        })
      })
    }
  }
};
</script>

<style scoped>
</style>
